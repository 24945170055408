<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10C1 6.25027 1 4.3754 1.95491 3.06107C2.26331 2.6366 2.6366 2.26331 3.06107 1.95491C4.3754 1 6.25027 1 10 1C13.7497 1 15.6246 1 16.9389 1.95491C17.3634 2.26331 17.7367 2.6366 18.0451 3.06107C19 4.3754 19 6.25027 19 10V12C19 15.7497 19 17.6246 18.0451 18.9389C17.7367 19.3634 17.3634 19.7367 16.9389 20.0451C15.6246 21 13.7497 21 10 21C6.25027 21 4.3754 21 3.06107 20.0451C2.6366 19.7367 2.26331 19.3634 1.95491 18.9389C1 17.6246 1 15.7497 1 12V10Z"
      :stroke="iconStroke"
      stroke-width="1.5"
    />
    <path
      d="M13 12L12.1429 10M7 12L7.85714 10M7.85714 10L9.40756 6.38235C9.50675 6.15092 9.74059 6 10 6C10.2594 6 10.4933 6.15092 10.5924 6.38235L12.1429 10M7.85714 10H12.1429"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 16L14 16"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '22',
    },
    width: {
      type: String,
      default: '20',
    },
    color: {
      type: String,
      default: 'primary-purple-600',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStroke() {
      return `var(--${this.color})`
    },
  },
}
</script>
